import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { EMPTY, Observable } from 'rxjs';

import { Payload } from './stream';

export type EndEvent = 'tabLeave' | 'tabReturn' | 'tabClose' | 'pageLeave' | 'videoPaused' | 'videoResumed'

interface GetStreamDetails {
    (id: string): Observable<Payload | ApiErrorI>
}
interface SendStreamEndEvent {
    (id: string, endEvent: EndEvent): Observable<never>
}
export interface StreamApi {
    getStreamDetails: GetStreamDetails
    getStreamDetailsPermanent: GetStreamDetails
    sendStreamEndEvent: SendStreamEndEvent
    sendStreamPermanentEndEvent: SendStreamEndEvent
}

export const getStreamDetails: GetStreamDetails = (id): Observable<Payload | ApiErrorI> =>
    apiRxjs.get(`/frontapi/calls/${id}/`);

export const getStreamDetailsPermanent: GetStreamDetails = (id): Observable<Payload | ApiErrorI> =>
    apiRxjs.get(`/frontapi/templates/${id}/stream/`);

const sendEndEvent = (path: string, endEvent: EndEvent): Observable<never> => {
    navigator.sendBeacon(`${path}?event=${endEvent}`);

    return EMPTY;
};

export const sendStreamEndEvent: SendStreamEndEvent = (id, endEvent) =>
    sendEndEvent(`/frontapi/calls/${id}/`, endEvent);

export const sendStreamPermanentEndEvent: SendStreamEndEvent = (id, endEvent) =>
    sendEndEvent(`/frontapi/templates/${id}/stream/`, endEvent);

export const streamApi = {
    getStreamDetails,
    getStreamDetailsPermanent,
    sendStreamEndEvent,
    sendStreamPermanentEndEvent,
};

export default getStreamDetails;
