import HttpError from 'util/httpError';
import { ApiErrorI } from 'util/api.rxjs';
import { Language } from '@yandex-int/i18n';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { Selector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { VIDEO_PAUSED, VIDEO_RESUMED } from 'ducks/streamPlayer';
import { StoreI } from '../store';

import { convertEventPayload } from './stream.utils';
import { EndEvent } from './stream.api';

const REQUEST = 'vconf/stream/REQUEST';
const REQUEST_PERMANENT = 'vconf/stream/REQUEST_PERMANENT';
const FLUSH = 'vconf/stream/FLUSH';
const FLUSH_ERROR = 'vconf/stream/FLUSH_ERROR';
const RECEIVE = 'vconf/stream/RECEIVE';
const ERROR = 'vconf/stream/ERROR';
const END_EVENT = 'vconf/stream/END_EVENT';

export interface SendStreamEndEventPayload {
    id: string;
    endEvent: EndEvent;
}

export const requestStreamDetails = createAction(REQUEST);
export const requestStreamDetailsPermanent = createAction(REQUEST_PERMANENT);
export const flushStreamDetails = createAction(FLUSH);
export const flushStreamError = createAction(FLUSH_ERROR);
export const receiveStreamDetails = createAction(RECEIVE);
export const errorStreamDetails = createAction(ERROR);
export const streamEndEvent = createAction<SendStreamEndEventPayload>(END_EVENT);

export interface EventPayload {
    id: number;
    start_time: string;
    end_time: string;
    description: string;
}

export type TranslatedStreamsPayload = Partial<Record<Language, string>>;

export interface Payload {
    name: string;
    stream_description: string;
    chat_invite_hash: string;
    stream_id: string;
    retranslator_host: string;
    ether_url: string;
    ether_back_id: string;
    event_id: number;
    id: string;
    template_id: number;
    viewers_count: number;
    uri: string;
    stop_time: string;
    event?: EventPayload;
    translated_streams?: TranslatedStreamsPayload;
    lang?: Language;
}

export interface ErrorDetails {
    event?: {
        startTs: string,
        name: string,
        id: number,
    }
}

type IPayload = Payload & HttpError<ErrorDetails>;

export interface StreamEvent {
    id: number;
    startTime: string;
    endTime: string;
    description: string;
}

export type TranslatedStreams = Partial<Record<Language, string>>;

export interface Stream {
    responseError?: HttpError<ErrorDetails>;
    isRequesting: boolean;
    name: string;
    chatInviteHash: string;
    streamId: string;
    retranslatorHost: string;
    etherUrl: string;
    etherBackId: string;
    eventId: number;
    id: string | null;
    templateId: number;
    streamDescription: string;
    viewersCount: number;
    uri: string;
    stopTime: string;
    event?: StreamEvent;
    translatedStreams?: TranslatedStreams;
    lang?: Language;
    isPermanent: boolean;
}

export const initialState: Stream = {
    responseError: null,
    isRequesting: false,
    name: '',
    chatInviteHash: '',
    streamId: '',
    retranslatorHost: '',
    etherUrl: null,
    etherBackId: null,
    eventId: null,
    id: null,
    templateId: null,
    streamDescription: null,
    viewersCount: null,
    uri: '',
    stopTime: '',
    isPermanent: false,
};

export const reducer = handleActions<Stream, IPayload>(
    {
        [REQUEST]: (state): Stream => ({
            ...state,
            isRequesting: true,
            isPermanent: false,
        }),

        [ERROR]: (state, action: Action<HttpError<ErrorDetails>>): Stream => {
            const { payload } = action;
            return ({
                ...initialState,
                responseError: payload,
            });
        },

        [REQUEST_PERMANENT]: (state): Stream => ({
            ...state,
            isRequesting: true,
            isPermanent: true,
        }),

        [FLUSH_ERROR]: (state): Stream =>({
            ...state,
            responseError: null,
        }),

        [FLUSH]: (): Stream => initialState,

        [RECEIVE]: (state, { payload }): Stream => {
            const stream = {
                ...state,
                isRequesting: false,
                name: payload.name,
                streamDescription: payload.stream_description,
                chatInviteHash: payload.chat_invite_hash,
                streamId: payload.stream_id,
                retranslatorHost: payload.retranslator_host,
                etherUrl: payload.ether_url,
                etherBackId: payload.ether_back_id,
                eventId: payload.event_id,
                id: payload.id,
                templateId: payload.template_id,
                viewersCount: payload.viewers_count,
                uri: payload.uri,
                stopTime: payload.stop_time,
                translatedStreams: payload.translated_streams,
                lang: payload.lang,
            };

            if (payload.event) {
                stream.event = convertEventPayload(payload.event);
            }

            return stream;
        },
    },
    initialState,
);

export const epic = combineEpics(
    (action$: Observable<Action<string>>,
        $state: StateObservable<StoreI>,
        { streamApi: { getStreamDetails } }): Observable<Action<Payload | INotificationMessageType>> =>
        action$.pipe(
            ofType(REQUEST),
            mergeMap(({ payload }): Observable<Action<Payload | INotificationMessageType>> =>
                getStreamDetails(payload).pipe(
                    map((response): Action<Payload> => {
                        const possiblyErrorResponse = response as ApiErrorI;
                        if (possiblyErrorResponse.error) {
                            const code = possiblyErrorResponse.response_code;
                            const message = possiblyErrorResponse.message;

                            return errorStreamDetails(
                                new HttpError<ErrorDetails>(code, message),
                            );
                        }
                        return receiveStreamDetails(response);
                    }),
                ),
            ),
        ),
    (action$: Observable<Action<string>>,
        $state: StateObservable<StoreI>,
        { streamApi: { getStreamDetailsPermanent } }): Observable<Action<Payload | INotificationMessageType>> =>
        action$.pipe(
            ofType(REQUEST_PERMANENT),
            mergeMap(({ payload }): Observable<Action<Payload | INotificationMessageType>> =>
                getStreamDetailsPermanent(payload).pipe(
                    map((response): Action<Payload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            const code = possiblyErrorResponse.response_code;
                            const message = possiblyErrorResponse.message;
                            const errorCode = possiblyErrorResponse.error_code;
                            const errorDetails = possiblyErrorResponse.error_details;

                            return errorStreamDetails(
                                new HttpError(code, message, errorCode, errorDetails),
                            );
                        }

                        return receiveStreamDetails(response);
                    }),
                ),
            ),
        ),
    (action$: Observable<Action<never>>,
        { value: { stream } }: StateObservable<StoreI>,
        _): Observable<Action<SendStreamEndEventPayload>> =>
        action$.pipe(
            filter(({ type }) => (type === VIDEO_PAUSED || type === VIDEO_RESUMED) && stream.id !== null),
            mergeMap(({ type }) => of(streamEndEvent({
                id: stream.id,
                endEvent: type === VIDEO_PAUSED ? 'videoPaused' : 'videoResumed',
            }))),
        ),
    (action$: Observable<Action<SendStreamEndEventPayload>>,
        { value: { stream } }: StateObservable<StoreI>,
        { streamApi: { sendStreamEndEvent, sendStreamPermanentEndEvent } }): Observable<Action<never>> =>
        action$.pipe(
            ofType(END_EVENT),
            mergeMap(({ payload }): Observable<Action<never>> => {
                const request = stream.isPermanent ? sendStreamPermanentEndEvent : sendStreamEndEvent;

                return request(payload.id, payload.endEvent).pipe(
                    ignoreElements(),
                    catchError(() => EMPTY),
                );
            }),
        ),
);

export const selectStreamDetails: Selector<StoreI, Stream> = ({ stream }): Stream => stream;
