import { useCallback, useEffect, useRef } from 'react';

export interface LeavePageHandlersPayload {
    /** пользователь переключился на другую вкладку */
    onTabLeave?: () => void;
    /** пользователь вернулся из другой вкладки */
    onTabReturn?: () => void;
    /** пользователь закрыл вкладку */
    onTabClose?: () => void;
    /** пользователь переключился на другую страницу (навигация внутри вкладки) */
    onPageLeave?: () => void;
}

// eslint-disable-next-line valid-jsdoc
/** хук поддерживает передачу как инлайн, так и ссылочных функций */
export const useLeaveHandlers = (payload: LeavePageHandlersPayload = {}) => {
    const status = useRef<'empty' | 'tabClose' | 'pageLeave'>('empty');

    const handlers = useRef<Required<LeavePageHandlersPayload>>({
        onTabLeave: () => {},
        onTabReturn: () => {},
        onTabClose: () => {},
        onPageLeave: () => {},
    });
    handlers.current = Object.assign(handlers.current, payload);

    /**
     * такое странное решение было сделано,
     * потому что другого стабильного способа отлавливать внутреннюю навигацию с текущим роутером нет
     * */
    const currentHref = useRef(window.location.href);
    const pageLeaveObserver = useRef(new MutationObserver(() => {
        if (window.location.href !== currentHref.current) {
            status.current = 'pageLeave';
            currentHref.current = window.location.href;
            handlers.current.onPageLeave();
            pageLeaveObserver.current.disconnect();
        }
    }));

    const beforeunloadHandler = useCallback(() => {
        status.current = 'tabClose';
        handlers.current.onTabClose();
    }, []);

    const visibilitychangeHandler = useCallback(() => {
        if (status.current === 'pageLeave' || status.current === 'tabClose') return;

        if (document.visibilityState === 'hidden') handlers.current.onTabLeave();
        else handlers.current.onTabReturn();
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', beforeunloadHandler, { passive: true });
        window.addEventListener('visibilitychange', visibilitychangeHandler, { passive: true });

        pageLeaveObserver.current.disconnect();
        pageLeaveObserver.current.observe(document.body, { childList: true, subtree: true });

        return () => {
            window.removeEventListener('beforeunload', beforeunloadHandler);
            window.removeEventListener('visibilitychange', visibilitychangeHandler);
        };
    }, [beforeunloadHandler, visibilitychangeHandler]);
};
